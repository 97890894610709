@import url("./vars.css");

.Content {
  width: var(--content-width);
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: calc(var(--menu-height) * 1.4) 5vw 2vw 2vw;
  box-sizing: border-box;
}
