@font-face {
  font-family: "Playwrite AT";
  src: url("fonts/PlaywriteAT/PlaywriteAT-Regular.ttf");
}

@font-face {
  font-family: "Pirata One";
  src: url("fonts/PirataOne-Regular.ttf");
}

.Text {
  width: calc(var(--content-width) * 0.875);
}

.Text h1 {
  font-family: "Playwrite AT";
  font-size: 4vw;
}

.Text h2 {
  font-family: monospace;
}

.Text p {
  font-family: monospace;
  font-size: 1.3vw;
  line-height: 1.5;
}
