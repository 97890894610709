@import url("./vars.css");

.Footer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: calc(var(--menu-height) * 0.6);
  border-radius: 15px;
  bottom: 2vh;
  padding: 1vw 1.8vw 1vw 2vw;
  margin: 2vw 10vw 1vw 2vw;
  width: calc(var(--content-width) * 0.83);
  box-sizing: bor;

  /* Background with transparency */
  background-color: rgba(255, 255, 255, 0.8); /* White with 50% transparency */

  /* Blur effect */
  backdrop-filter: blur(2px); /* Blurs the background behind the menu */

  /* Optional: add a subtle shadow to the menu bar */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.FooterItem {
  padding: 1vw 2vw 1vw 2vw;
  display: flex;
  justify-content: center;
}

.FooterItem a {
  display: flex;
  align-items: center;
  font-family: monospace;
  font-size: 1vw;
  text-decoration: none;
  color: black;
}

.FooterItem a:visited {
  color: inherit;
}

.FooterItem a:hover {
  color: inherit;
  text-decoration: underline;
}

.FooterItem a:active {
  color: inherit;
}

.FooterItem img {
  height: 2vw;
  margin: 0 0.3vw 0 0;
}

.FooterHint {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FooterHint img {
  width: 3%;
}

.FooterHint a {
  font-family: "Playwrite At";
  font-size: 1vw;
  text-decoration: none;
  color: black;
}
