.Timeline {
  list-style-type: none;
}

.TimelineItem {
  display: inline-flex;
  position: relative;
  flex-flow: row;
  align-items: center;
}

.TimelineItem p {
  font-size: 1vw;
  white-space: nowrap;
  display: inline-block;
}

.TimelineItem .Info {
  position: relative;
  display: inline-block;
  margin-left: 0.5vw;
  margin-right: 1vw;
  white-space: nowrap;
}
.TimelineItem .Info:hover {
  cursor: pointer;
}

.TimelineItem .Description {
  display: none;
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%); /* Centers vertically */
  white-space: normal;
  font-family: monospace;
  font-size: 0.8vw;
  color: #fff;

  padding: 10px;
  border-radius: 5px;
  background-color: #000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Adds a shadow for the popup effect */
  white-space: normal; /* Allow wrapping inside the popup */
  z-index: 10; /* Ensures it appears above other elements */
  width: 10vw;
}

.TimelineItem .Info:hover + .Description,
.Description {
  display: block;
  animation: fade-in 0.4s forwards; /* Trigger animation */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
