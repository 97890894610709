@import url("./vars.css");

.Menu {
  height: var(--menu-height);
  width: calc(var(--content-width) * 0.9);
  margin: 2vw 10vw 2vw 2vw;
  /* padding: 2vw 10vw 2vw 0vw; */
  border-radius: 15px;
  box-sizing: border-box; /* Ensures padding and border are part of the width */
  position: fixed;
  z-index: 1000; /* Ensures the footer stays above other content */
  /* overflow: auto; */
  display: flex;
  justify-content: flex-start;
  align-items: center;

  /* Background with transparency */
  background-color: rgba(255, 255, 255, 0.8); /* White with 50% transparency */

  /* Blur effect */
  backdrop-filter: blur(2px); /* Blurs the background behind the menu */

  /* Optional: add a subtle shadow to the menu bar */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.divider {
  font-size: 1.5vw;
  margin: 0 1vw 0 1vw;
}

.MenuItem {
  font-family: monospace;
  font-size: 1.25vw;
  padding: 1vw 2vw 1vw 2vw;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.MenuItem:visited {
  color: inherit;
}

.MenuItem:hover {
  color: inherit;
  text-decoration: underline;
}

.MenuItem:active {
  color: inherit;
}

.MenuSwitch {
  margin: 0 2vw 0 1vw;
}

.SwitchArea p {
  position: fixed;
  margin-left: 61vw;
  font-family: monospace;
  font-size: 1vw;
}
