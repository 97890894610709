.Music {
  display: flex;
}

.Music h1 {
  font-family: "Playwrite AT";
  font-size: 4vw;
}

.Song h2 {
  font-family: monospace;
}

.Song label {
  font-family: "Playwrite AT";
  font-size: smaller;
  color: #303030;
  margin-bottom: 1vh;
}

.songList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1vw;
}

.Song {
  background-color: #ffcc80;
  padding: 0.5vh 0.5vw 1.5vh 0.5vw;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  margin-bottom: 2vw;
  text-align: center;
  transition: transform 0.2s ease-in-out;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  animation-fill-mode: forwards;
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 14vw;
}

.Song audio {
  margin: 0 auto;
}

.Song:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

/* To fade in song tiles */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* animation for active song playing */
@keyframes pastelBackground {
  0% {
    background-color: #b39ddb;
  }
  25% {
    background-color: #81d4fa;
  }
  50% {
    background-color: #80cbc4;
  }
  75% {
    background-color: #ffcc80;
  }
  100% {
    background-color: #ef9a9a;
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.Song.playing {
  background: linear-gradient(
    270deg,
    #b39ddb,
    #81d4fa,
    #80cbc4,
    #ffcc80,
    #ef9a9a
  );
  opacity: 1;
  background-size: 400% 400%;
  animation: gradientShift 8s ease infinite,
    pastelBackground 10s linear infinite;
}
