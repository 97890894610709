.ProfilePicture img {
  justify-content: flex-end;
  width: 45vw;
  object-fit: cover;
  opacity: 0;
}

.fade-in {
  animation: fade-in 1s forwards; /* Trigger animation */
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.98;
  }
}
